import {
  DEFAULT_SORT_ORDER,
  DEFAULT_SORT_ORDER_BY_COLLABORATOR,
  NEWEST,
  OLDEST,
  NEWEST_BY_COLLABORATOR,
  OLDEST_BY_COLLABORATOR,
  LONGEST_UNANSWERED,
  NEWEST_BY_DELETED,
  NEWEST_BY_SPAM,
  NEWEST_BY_CLOSED,
} from 'constants/defaults'
import { CLOSED, SPAM } from 'constants/ticket_state_types'
import { uniq } from 'util/arrays'
import { SORT_ORDERS_FOR_FOLDER } from 'util/folders/SORT_ORDERS_FOR_FOLDER'
import { buildId, isGid } from 'util/globalId'
import { constructSearchQueryObject } from 'util/search'
import {
  hasDeletedQueryFilter,
  hasFolderQueryFilter,
  hasStateQueryFilter,
} from 'util/search/queryObject'

function extractSearchTicketIds(search) {
  if (!search) return null
  const { pages } = search
  if (!pages) return null
  const pageValues = Object.values(pages)
  if (pageValues.length === 0) return []
  const ids = pageValues.reduce((a, b) => a.concat(b))
  return uniq(ids)
}

function sortToString(strOrObject) {
  let orderByWithDirection = strOrObject || ''
  if (orderByWithDirection && typeof strOrObject === 'object') {
    const { field, direction } = strOrObject
    // eslint-disable-next-line no-undef
    orderByWithDirection = [field, direction].join('_')
  }
  return orderByWithDirection
}

export const byNewest = (strOrObject = '') => {
  const orderByWithDirection = sortToString(strOrObject)
  return [
    NEWEST,
    NEWEST_BY_COLLABORATOR,
    'LAST_USER_MESSAGE_AT_DESC',
    'LAST_USER_OR_AGENT_MESSAGE_AT_DESC',
    'LAST_USER_OR_NOTE_MESSAGE_AT_DESC',
    'LAST_USER_AGENT_OR_NOTE_MESSAGE_AT_DESC',
    'LAST_UNANSWERED_USER_MESSAGE_AT_ASC',
  ].includes(orderByWithDirection)
}

export const byOldest = (strOrObject = '') => {
  const orderByWithDirection = sortToString(strOrObject)
  return [
    OLDEST,
    OLDEST_BY_COLLABORATOR,
    'LAST_USER_MESSAGE_AT_ASC',
    'LAST_USER_OR_AGENT_MESSAGE_AT_ASC',
    'LAST_USER_OR_NOTE_MESSAGE_AT_ASC',
    'LAST_USER_AGENT_OR_NOTE_MESSAGE_AT_ASC',
  ].includes(orderByWithDirection)
}

export const byLongestUnanswered = (strOrObject = '') => {
  const orderByWithDirection = sortToString(strOrObject)
  return LONGEST_UNANSWERED === orderByWithDirection
}

export const byNewestSpam = (strOrObject = '') => {
  const orderByWithDirection = sortToString(strOrObject)
  return NEWEST_BY_SPAM === orderByWithDirection
}

export const byNewestClosed = (strOrObject = '') => {
  const orderByWithDirection = sortToString(strOrObject)
  return NEWEST_BY_CLOSED === orderByWithDirection
}

export const byNewestDeleted = (strOrObject = '') => {
  const orderByWithDirection = sortToString(strOrObject)
  return NEWEST_BY_DELETED === orderByWithDirection
}

const sortToCollaboratorUpdatedAtMapping = new Map([
  [NEWEST, NEWEST_BY_COLLABORATOR],
  [OLDEST, OLDEST_BY_COLLABORATOR],
])

const sortFuncs = {
  [NEWEST]: (a, b) => Date.parse(b.updated_at) - Date.parse(a.updated_at),
  [OLDEST]: (a, b) => Date.parse(a.updated_at) - Date.parse(b.updated_at),
  [NEWEST_BY_COLLABORATOR]: (a, b) =>
    Date.parse(b.latest_collaborator_comment_at) -
    Date.parse(a.latest_collaborator_comment_at),
  [OLDEST_BY_COLLABORATOR]: (a, b) =>
    Date.parse(a.latest_collaborator_comment_at) -
    Date.parse(b.latest_collaborator_comment_at),
  [LONGEST_UNANSWERED]: (a, b) =>
    Date.parse(a.last_unanswered_user_message_at) -
    Date.parse(b.last_unanswered_user_message_at),
  [NEWEST_BY_DELETED]: (a, b) =>
    Date.parse(b.deleted_at) - Date.parse(a.deleted_at),
  [NEWEST_BY_CLOSED]: (a, b) =>
    Date.parse(b.stateChangedAt) - Date.parse(a.stateChangedAt),
  [NEWEST_BY_SPAM]: (a, b) =>
    Date.parse(b.stateChangedAt) - Date.parse(a.stateChangedAt),
}

const defaultSortFunc = (sortOptions = {}) => {
  const { sortByCollaboratorCommentAtEnabled } = sortOptions || {}

  const sortOrder = sortByCollaboratorCommentAtEnabled
    ? DEFAULT_SORT_ORDER_BY_COLLABORATOR
    : DEFAULT_SORT_ORDER

  return sortFuncs[sortOrder]
}

export const sortSearchIds = (query, sortOrder, byId, sortOptions = {}) => {
  const { sortByCollaboratorCommentAtEnabled } = sortOptions || {}

  const ids = extractSearchTicketIds(query)

  let sortFunc
  if (
    sortByCollaboratorCommentAtEnabled &&
    sortToCollaboratorUpdatedAtMapping.has(sortOrder)
  ) {
    sortFunc = sortFuncs[sortToCollaboratorUpdatedAtMapping.get(sortOrder)]
  } else {
    sortFunc = sortFuncs[sortOrder] || defaultSortFunc(sortOptions)
  }

  const { queryId } = query
  const isIdQuery = !isNaN(queryId)
  const orderedResults = ids.sort((idA, idB) => {
    const a = byId[idA]
    const b = byId[idB]
    if (!a || !b) return 0
    if (isIdQuery) {
      if (a.id == queryId) return -1 // eslint-disable-line eqeqeq
      if (b.id == queryId) return 1 // eslint-disable-line eqeqeq
    }
    if (a.updated_at === b.updated_at) {
      if (a.id < b.id) return 1
      if (a.id > b.id) return -1
    }
    return sortFunc(a, b)
  })
  return orderedResults
}

const API_MAP = {
  [NEWEST]: 'updated_at desc',
  [OLDEST]: 'updated_at asc',
  [NEWEST_BY_COLLABORATOR]: 'latest_collaborator_comment_at desc',
  [OLDEST_BY_COLLABORATOR]: 'latest_collaborator_comment_at asc',
  [LONGEST_UNANSWERED]: 'last_unanswered_user_message_at asc',
  [NEWEST_BY_SPAM]: 'state_changed_at desc',
  [NEWEST_BY_CLOSED]: 'state_changed_at desc',
  [NEWEST_BY_DELETED]: 'deleted_at desc',
}

const defaultAPISortOrder = (sortOptions = {}) => {
  const { sortByCollaboratorCommentAtEnabled } = sortOptions || {}

  const sortOrder = sortByCollaboratorCommentAtEnabled
    ? DEFAULT_SORT_ORDER_BY_COLLABORATOR
    : DEFAULT_SORT_ORDER

  return API_MAP[sortOrder]
}

export const toGraphQL = (sort, sortOptions = {}) => {
  const { sortByCollaboratorCommentAtEnabled } = sortOptions || {}

  if (
    sortByCollaboratorCommentAtEnabled &&
    sortToCollaboratorUpdatedAtMapping.has(sort)
  ) {
    return API_MAP[sortToCollaboratorUpdatedAtMapping.get(sort)]
  }

  return API_MAP[sort] || defaultAPISortOrder(sortOptions)
}

export const SORT_CONTEXT_KEY_DELIMITER = '##'

export const sortOrderContextKeyForQueryId = (
  queryId,
  foldersById,
  ticketSearchOperatorValueMap
) => {
  const queryObject = constructSearchQueryObject(
    queryId,
    ticketSearchOperatorValueMap
  )

  const hasDeletedQuery = hasDeletedQueryFilter(queryObject)
  const hasClosedQuery = hasStateQueryFilter(queryObject, CLOSED)
  const hasSpamQuery = hasStateQueryFilter(queryObject, SPAM)
  const hasFolderQuery = hasFolderQueryFilter(queryObject)

  const sortOrderKeys = new Set()

  // add the sort options depending on search
  if (hasDeletedQuery) {
    sortOrderKeys.add(NEWEST_BY_DELETED)
  }

  if (hasClosedQuery) {
    sortOrderKeys.add(NEWEST_BY_CLOSED)
  }

  if (hasSpamQuery) {
    sortOrderKeys.add(NEWEST_BY_SPAM)
  }

  if (!hasDeletedQuery && !hasClosedQuery && !hasSpamQuery && !hasFolderQuery) {
    sortOrderKeys.add(LONGEST_UNANSWERED)
  }

  // add the sort options depending on folder query
  if (hasFolderQuery) {
    const { folder: folders } = queryObject
    for (let i = 0; i < folders.length; i += 1) {
      const fq = folders[i]
      const folderId = isGid(fq) ? fq : buildId('Folder', fq)
      const folder = foldersById[folderId]

      if (folder) {
        SORT_ORDERS_FOR_FOLDER.forEach((func, key) => {
          if (func(folder)) {
            sortOrderKeys.add(key)
          }
        })
      }
    }
  }

  // default search options
  sortOrderKeys.add(NEWEST)
  sortOrderKeys.add(OLDEST)

  return Array.from(sortOrderKeys).join(SORT_CONTEXT_KEY_DELIMITER)
}

export const sortOrderContextKeyToSortOptionKeys = contextKey => {
  if (!contextKey) return []

  return contextKey.split(SORT_CONTEXT_KEY_DELIMITER)
}
