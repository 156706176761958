// filter condition operator functions

export const OPERATOR_TYPE_EQ = 'EQ'
export const OPERATOR_TYPE_NOT_EQ = 'NOT_EQ'
export const OPERATOR_TYPE_LT = 'LT'
export const OPERATOR_TYPE_LTEQ = 'LTEQ'
export const OPERATOR_TYPE_GT = 'GT'
export const OPERATOR_TYPE_GTEQ = 'GTEQ'
export const OPERATOR_TYPE_CONTAINS = 'CONTAINS'
export const OPERATOR_TYPE_DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN'

export const eq = (a, b) => a === b
export const notEq = (a, b) => !eq(a, b)
export const lt = (a, b) => a < b
export const gt = (a, b) => a > b
export const lteq = (a, b) => a <= b
export const gteq = (a, b) => a >= b

export const contains = (arr = [], str = '') =>
  str && arr.findIndex(elem => elem.toLowerCase() === str.toLowerCase()) > -1

export const doesNotContain = (arr = [], str) => !contains(arr, str)

export const getOperatorFn = operator => {
  switch (operator) {
    case OPERATOR_TYPE_EQ:
      return eq
    case OPERATOR_TYPE_NOT_EQ:
      return notEq
    case OPERATOR_TYPE_LT:
      return lt
    case OPERATOR_TYPE_LTEQ:
      return lteq
    case OPERATOR_TYPE_GT:
      return gt
    case OPERATOR_TYPE_GTEQ:
      return gteq
    case OPERATOR_TYPE_CONTAINS:
      return contains
    case OPERATOR_TYPE_DOES_NOT_CONTAIN:
      return doesNotContain
    default:
      return null
  }
}

export const MATCH_TYPE_ANY = 'ANY'
export const MATCH_TYPE_ALL = 'ALL'
