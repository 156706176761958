import { capitalize, escapeRegExp, pluralize } from './strings'

const incrementOrInit = (object, key) => (object[key] ? object[key] + 1 : 1)

export function findAgentById(agents, agent) {
  const fullAgent = agents.find(a => a.id === agent.id)
  if (!fullAgent) return null
  return fullAgent
}

export function findAgentByUsername(agents, username) {
  const fullAgent = agents.find(a => a.username === username)
  if (!fullAgent) return null
  return fullAgent
}

export function getAgentNameCounts(agents) {
  return agents.reduce((counted, a) => {
    return {
      ...counted,
      [a.first_name]: incrementOrInit(counted, a.first_name),
      [a.initial]: incrementOrInit(counted, a.initial),
      [a.name]: incrementOrInit(counted, a.name),
    }
  }, {})
}

export function getShortAgentName(
  agents,
  agent,
  { precalculatedCounts = null, disableMutate = false } = {}
) {
  if (!agent) return null
  const fullAgent = findAgentById(agents, agent)
  if (!fullAgent) return null
  // if already generated, reuse
  if (fullAgent.shortName) return fullAgent.shortName
  // if cached, reuse
  // eslint-disable-next-line no-underscore-dangle
  if (fullAgent._cached_shortName) return fullAgent._cached_shortName
  if (!fullAgent) return null
  // agent labels should just be:
  // 1. if not dupe agent first names in account just first name (Jordan)
  // 2. if first names are dupe but last name's initals are different (Jordan G)
  // 3. otherwise whole name (Jordan Glasner)
  const counts = precalculatedCounts || getAgentNameCounts(agents)
  const found = [fullAgent.first_name, fullAgent.initial, fullAgent.name].find(
    candidate => counts[candidate] < 2
  )
  const result = found || fullAgent.name
  // mutate object for cache, should we see it again
  if (!disableMutate && Object.isExtensible(fullAgent)) {
    // eslint-disable-next-line no-underscore-dangle
    fullAgent._cached_shortName = result
  }
  return result
}

export function getAgentUsername(agent) {
  if (!agent) return undefined
  if (!agent.username) return undefined
  return agent.username.replace(/^@/, '')
}

export const name = agent => {
  if (!agent) return ''
  return agent.agentFullName || agent.name || ''
}

export const initials = (agent, length = 2) =>
  name(agent)
    .split(' ')
    .map(n => n.charAt(0))
    .join('')
    .slice(0, length)

export function getAgentUsernameOrYou(agent, currentUser) {
  if (agent.id === currentUser.id) return 'you'
  return getAgentUsername(agent)
}

export function getAgentPossessiveUsernameOrMine(agent, currentUser) {
  if (agent.id === currentUser.id) return 'Mine'
  const username = getAgentUsername(agent)
  if (!username) return ''
  return `${capitalize(username)}'${username.match(/s$/) ? '' : 's'}`
}

export function getAgentNameOrYou(agent, currentUser, agentsById) {
  if (agent.id === currentUser.id) return 'You'
  return capitalize(getShortAgentName(Object.values(agentsById), agent))
}

export function filterAgentsByTerm(agents, term) {
  let pattern
  try {
    pattern = new RegExp(`^${escapeRegExp(term)}`, 'i')
  } catch (e) {
    return agents
  }
  return agents.filter(agent => {
    return (
      pattern.test(agent.first_name) ||
      pattern.test(agent.last_name) ||
      pattern.test(agent.email) ||
      pattern.test(getAgentUsername(agent))
    )
  })
}

export function getAgentAvatar(agent) {
  const missingAvatarUrl = `${
    app.path === '/' ? '' : app.path
  }/assets/avatar_missing.png`

  return agent ? agent.avatar_url : missingAvatarUrl
}

export const fullName = agent =>
  name(agent) ||
  (agent.last_name === null
    ? agent.first_name
    : `${agent.first_name} ${agent.last_name}`)

export const isOwnerRole = role => ['owner', 'OWNER'].includes(role)
export const isAdminRole = role =>
  ['admin', 'ADMIN'].includes(role) || isOwnerRole(role)
export const isAgentRole = role => ['agent', 'AGENT'].includes(role)
export const isViewerRole = role => ['viewer', 'VIEWER'].includes(role)

export const isOwner = agent => isOwnerRole(agent.role)

export const isAdmin = agent => isAdminRole(agent.role)

export const isAgent = agent => isAgentRole(agent.role)

export const isViewer = agent => isViewerRole(agent.role)

export function collisionLabelTextForUsers(users, type) {
  if (!users || (users && users.length <= 0)) return null

  const names = users.map(user => user.first_name)
  const rest = users.length - 1

  const label =
    names.length === 1
      ? `${names[0]} is`
      : `${names[0]} and ${rest} other ${pluralize(rest, app.t('agent'))} are`

  let action
  if (type === 'noting') {
    action = 'leaving a note'
  } else if (['typingReply', 'typingNote'].includes(type)) {
    action = 'typing'
  } else {
    action = type
  }

  return `${label} ${action}`
}

export default {
  getShortAgentName,
  getAgentUsername,
}
