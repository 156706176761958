export const GID_REGEX = /^(?<type>[0-9a-zA-Z]+)_(?<id>.*?)$/
export const PREFIX_MAPPING = {
  Account: 'ac',
  Agent: 'ag',
  AgentChanged: 'agch',
  AgentPreference: 'agp',
  AiSuggestion: 'ais',
  Article: 'art',
  ArticleAttachment: 'aa',
  ArticleRating: 'ar',
  Attachment: 'at',
  CannedReply: 'cr',
  CannedReplyCategory: 'crc',
  Changeset: 'cs',
  ChangesetGroup: 'csg',
  Channel: 'ch',
  ChannelChanged: 'chch',
  Company: 'cm',
  Condition: 'cnd',
  Contact: 'co',
  ContactChange: 'coch',
  ContactOpenedMessage: 'coo',
  Conversation: 'cnv',
  ConversationDeleted: 'cnvd',
  ConversationMerged: 'cnvm',
  ConversationRestored: 'cnvr',
  ConversationStarred: 'cnvs',
  ConversationUnstarred: 'cnvus',
  CommentReacted: 'mr',
  CommentDeleted: 'md',
  CommentEdited: 'me',
  CustomerRatingSetting: 'crs',
  CustomerReadChange: 'crd',
  CustomField: 'cf',
  CustomFieldCategory: 'cfc',
  CustomFieldValue: 'cfv',
  Draft: 'dra',
  EmailMarketingIntegration: 'emi',
  EmailMarketingIntegrationMailboxSetting: 'emims',
  EmailMessage: 'msg',
  Event: 'evt',
  EventGroupType: 'evtg',
  ExcludedDomain: 'exd',
  Export: 'exp',
  Folder: 'fol',
  FollowerAdded: 'fla',
  FollowerRemoved: 'flr',
  Handle: 'hdl',
  IntegrationAdded: 'inta',
  IntegrationExternalChange: 'inte',
  IntegrationRemoved: 'intr',
  KbCategory: 'kbc',
  KnowledgeBase: 'kb',
  Label: 'lbl',
  Message: 'msg',
  Rating: 'rat',
  Rule: 'rul',
  RuleReplyTemplate: 'rrt',
  Segment: 'sg',
  Snoozed: 'snz',
  StateChanged: 'stc',
  SubjectChanged: 'subjc',
  Tag: 'tag',
  Tagged: 'cnvt',
  Team: 'tm',
  TeamChanged: 'tmc',
  Theme: 'thm',
  Unsnoozed: 'unsnz',
  Untagged: 'unt',
  Widget: 'wid',
}

const TYPE_LOOKUP = Object.keys(PREFIX_MAPPING).reduce((lookup, type) => {
  const prefix = PREFIX_MAPPING[type]
  // eslint-disable-next-line no-param-reassign
  lookup[prefix] = type
  return lookup
}, {})

export function buildId(type, id) {
  if (!id) return id
  if (isGid(id)) return id

  return `${PREFIX_MAPPING[type]}_${id}`
}

function getSeparatorIndex(id) {
  return id.indexOf('_')
}

export function getRawId(inputId) {
  if (!inputId) return null
  const id = inputId.toString()
  if (!id.includes('_')) return id
  const separatorIndex = getSeparatorIndex(id)
  const type = getType(id)
  if (!type) return null

  return id.substr(separatorIndex + 1)
}

export function buildIdFromAny(type, id) {
  return buildId(type, getRawId(id))
}

export function isGid(id) {
  if (!id) return false
  const strId = id.toString()
  const match = strId.match(GID_REGEX)
  if (!match) return false
  if (!TYPE_LOOKUP[match.groups.type]) return false

  return true
}

export function getType(id) {
  if (!isGid(id)) return null

  const separatorIndex = getSeparatorIndex(id)
  const type = id.substr(0, separatorIndex)

  return TYPE_LOOKUP[type]
}
