import { getLatestCustomerReply } from 'util/actions'
import { uniq } from 'util/arrays'
import { twitterHandle } from 'ducks/customers/utils'
import { getValueByPath } from 'util/objects'
import { extractTwitterHandles } from 'util/strings'

export const RECIPIENT_TYPE_GROUPS = ['to', 'cc', 'bcc']

export const hasAttachments = ticket => {
  if (ticket.attachment_count && ticket.attachment_count > 0) return true
  if (!ticket.actions || !ticket.actions.records) return false

  return ticket.actions.records.some(
    action =>
      action.change_type === 'Message' &&
      action.preview &&
      action.preview[1] > 0
  )
}

export function reduceLabels(labels) {
  if (!labels) return {}

  return {
    labelIds: uniq(labels.map(label => label.id || label)),
  }
}

export function sanitizeTicketBody(body) {
  if (!body) return ''
  return body
    .replace(/<p><br><\/p>/g, '<br>') // replace empty paragraphs into <br>'s
    .replace(/<\/p>/g, '<br>') // turn every paragraph into a string ending with <br>
    .replace(/<\/*p>/g, '') // remove all other <p> tag leftovers
    .replace(/<br>$/, '') // remove trailing <br>
}

export const isNewTicket = ticket => ticket && ticket.id === 'new'

export const isTwitter = ticket => ticket && ticket.type === 'Twitter'
export const isWidget = ticket => ticket && ticket.type === 'Widget'
export const isFacebook = ticket => ticket && ticket.type === 'Facebook'

export const isDirectMessage = ticket =>
  ticket && isTwitter(ticket) && ticket.subType === 'DirectMessage'

export function getAccountTwitterUsername(ticket) {
  if (!ticket) return ''
  const { subject } = ticket
  if (!subject) return ''

  const usernames = extractTwitterHandles(subject)
  return usernames.length > 0 ? usernames[0] : ''
}

export function getTwitterUsernames(ticket) {
  if (!ticket) return []
  const lastComment = getLatestCustomerReply(
    getValueByPath('actions.records', ticket)
  )
  if (!lastComment) return []

  const { body } = lastComment.change

  const actorHandle = twitterHandle(lastComment.actor)
  const accountHandle = getAccountTwitterUsername(ticket)
  const otherHandles = extractTwitterHandles(body).filter(
    item => item !== accountHandle
  )

  return [actorHandle, ...otherHandles]
}

export function isForward(ticket = {}) {
  const { title } = ticket
  if (!title) return false
  return !!title.match(/^Fwd:/i)
}
